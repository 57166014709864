<template>
  <div>
    <v-menu v-model="menu" :close-on-content-click="false" transition="scroll-y-transition" :max-width="475" :nudge-width="475" left offset-y :z-index="1001">
      <template v-slot:activator="{ on, attrs }">
        <v-btn :class="menu?'border-3':''" plain :ripple="false" v-bind="attrs" v-on="on">
          <v-icon left color="#4E4E4E">
            mdi-map-marker
          </v-icon>
          <span v-if="!loading" class="text-subtitle-1 text-capitalize font-weight-medium">{{ getValueInCookie() || 'Not Located' }}</span>
          <v-skeleton-loader v-if="loading" width="125px" height="28px" type="text" class="d-flex align-center"></v-skeleton-loader>
          <v-icon right x-small color="#4E4E4E">
            {{ !menu ? 'mdi-triangle-down' : 'mdi-triangle' }}
          </v-icon>
        </v-btn>
      </template>
      <v-card outlined class="border-20">
        <v-card-title class="d-flex justify-center pa-2 pb-0">
            <v-spacer></v-spacer>
            <v-icon right color="#4E4E4E" @click="closeMenu">mdi-close</v-icon>
        </v-card-title>
        <v-card-title v-if="showRedirectCard()" class="pa-6 pt-0">
          <v-card flat color="grey lighten-3" width="100%" class="d-flex column-row mr-4">
            <v-col cols="auto" class="pr-0">
              <v-icon color="secondary">mdi-map-marker-multiple</v-icon>
            </v-col>
            <v-col class="pl-0">
              <v-card-title>
                <p class="ma-0 break-word">Looks like you're in <span class="text-capitalize">{{ $cookies.get(COOKIENAME)?.city.toLowerCase() }}</span>!</p>
              </v-card-title>
              <v-card-text>
                <p class="ma-0 break-word">Navigate to the <span class="text-capitalize">{{ $cookies.get(COOKIENAME)?.city.toLowerCase() }}</span> page for local insights.</p>
              </v-card-text>
              <v-card-actions>
                <v-btn color="success" class="rounded-lg text-subtitle-1 text-capitalize custom-shadow" @click="gTagSendEvents('Search - Go To', `${$cookies.get(COOKIENAME)?.zip}`, `select_content`, `engagement`)" :href="getURLInCookie()">
                  <p class="ma-0">Go to {{ $cookies.get(COOKIENAME)?.city.toLowerCase() }} Page</p>
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-card>
        </v-card-title>
        <v-card-title class="px-4 py-0 pb-1">
            <span class="text-subtitle-1 font-weight-bold">My Location</span>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form ref="searchForm" lazy-validation @submit.prevent>
            <v-text-field v-model="searchValue" :placeholder="'Enter zip code...'" outlined dense color="secondary" class="rounded-lg" :disabled="readOnly" :rules="formRules.zipRules" :error-messages="displayErrorMessage">
              <template v-slot:prepend-inner>
                <v-icon color="secondary">mdi-map-marker</v-icon>
              </template>
              <template v-slot:append-outer>
                <v-slide-x-transition leave-absolute>
                  <v-btn @click="setCookieValue" outlined color="secondary" height="40px" class="mt-0 rounded-lg text-subtitle-1 text-capitalize custom-shadow" :loading="loading" :disabled="readOnly">
                    <strong>Update Location</strong>
                  </v-btn>
                </v-slide-x-transition>
              </template>
              <template v-slot:append>
                <v-icon v-if="searchValue" color="secondary" @click="clearInput">mdi-pencil-box-outline</v-icon>
              </template>
            </v-text-field>
          </v-form>
        </v-card-text>
      </v-card>
    </v-menu>
</div>
</template>

<script>
import i18n from "@/i18n";
import { districts_list } from '../plugins/districts';
import pako from 'pako';
export default {
  name: 'LocationSearch',
  props: {
    redirect: Boolean,
  },
  data() {
    return {
      i18n: i18n,
      loading: false,
      menu: false,
      readOnly: false,
      displayErrorMessage: '',
      searchValue: '',
      COOKIENAME: 'userLocation',
      locationData: {},
    };
  },
  computed: {
    formRules() {
      return {
        zipRules: [
            v => !!v || this.i18n.t('formRules.zip.required'),
            v => (v && v.length >= 2 && v.length <= 100) || this.i18n.t('formRules.zip.nbCaracters'),
            v => /^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/.test(v) || this.i18n.t('formRules.zip.required'),
        ],
      }
    }
  },
  async mounted() {
    if(this.$cookies.isKey(this.COOKIENAME)){
      this.loading = true;
      this.searchValue = this.getValueInCookie();
      await this.fetchMuleAPIDev(this.$cookies.get(this.COOKIENAME)?.zip);
      // this.editPhoneNumberInHTML(this.getPhoneInCookie());
    }
  },
  methods: {
    async setCookieValue() {
      if(!this.readOnly && this.$refs.searchForm.validate()){
        this.gTagSendEvents('Search - Update Location', this.$cookies.get(this.COOKIENAME)?.zip, "select_content", "engagement");
        await this.fetchMuleAPIDev();
      }
    },
    getValueInCookie() {
      const { zip: ZIP } = this.$cookies.get(this.COOKIENAME) || {};
      return ZIP ? `${ZIP}` : null;
    },
    getURLInCookie() {
      const URL = this.$cookies.get(this.COOKIENAME)?.url;
      return URL && URL !== window.location.hostname ? URL : null;
    },
    getPhoneInCookie() {
      const PHONE = this.$cookies.get(this.COOKIENAME)?.phone;
      return PHONE || null;
    },
    editPhoneNumberInHTML(newNumber) {
      const PHONE_ELEMENTS = document.querySelectorAll('.rp_phone-number');
      PHONE_ELEMENTS.forEach(element => element.innerText = newNumber);
    },
    async fetchMuleAPIDev(useZipFromCookie) {
      const startTime = performance.now(); // Start timing
      this.loading = true;
      try {
        const mappingResponse = await fetch('https://assets.wasteconnections.com/website-district-zip-mapping/district-zip-mapping.json.gz', {
          method: "GET",
        });

        const compressedData = await mappingResponse.arrayBuffer();

        // Decompress the gzipped data
        const decompressedData = pako.ungzip(new Uint8Array(compressedData), { to: 'string' });
        const zipMapping = JSON.parse(decompressedData)

        // Get the zip code from the cookie or search value
        const zipCode = useZipFromCookie || this.searchValue;

        // Check if the zip code exists in the mapping
        const district = zipMapping[zipCode];

        if (district) {
          // Make the API call using the mapped district
          const response = await fetch(`https://assets.wasteconnections.com/WebSiteJsons/${district}.json`, {
            method: "GET",
          });

          const data = await response.json();
          this.locationData = data[0];
          const COOKIEDATA = {
            zip: zipCode,
            city: this.locationData.SiteDetail.SiteAddress.City,
            url: this.locationData.ContactDetails.Website,
            phone: this.locationData.ContactDetails.CSPhone
          };
          this.$cookies.set(this.COOKIENAME, COOKIEDATA);
          this.searchValue = this.getValueInCookie();
          this.$refs.searchForm?.resetValidation();
          this.setReadOnly(true);
          await this.redirectRules(!useZipFromCookie);
        } else {
          this.displayErrorMessage = "Your ZIP code doesn't match our service area";
        }
      } catch (error) {
        this.displayErrorMessage = 'An error occurred. Please try again later';
      } finally {
        this.loading = false;
        const endTime = performance.now(); // End timing
        console.log(`fetchMuleAPIDev function took ${endTime - startTime} milliseconds.`);
      }
    },
    async fetchMuleAPIDevFromAddress(url) {
      this.loading = true;
      try {
        const response = await fetch(
          `https://muleapidev.wcnx.org/experience/site/Summary?fromAddress=${url}`, {
            method: "GET",
            headers: {
              "Authorization": "2236f4cfa96f20af2ca8cfdf9c5920f54d78f1b835318d729cgc2a72403cc29",
              "Accept-Encoding": "gzip, deflate, br",
              "Connection": "keep-alive",
            },
          }
        );
        const data = await response.json();
        const hostPathUrl = `https://www.wasteconnections.com${window.location.pathname}`.replace(/\/+$/, '');

        if (data[0]?.errorMessage) return false;

        const website = data[0]?.ContactDetails?.Website || '';
        const updatedWebsite = website.startsWith('http://')
          ? website.replace('http://', 'https://')
          : website.startsWith('https://')
            ? website
            : 'https://' + website;

        const finalWebsite = updatedWebsite.replace(/\/+$/, '');

        return hostPathUrl === finalWebsite;
      } catch (error) {
        this.displayErrorMessage = 'An error occurred. Please try again later';
      } finally {
        this.loading = false;
      }
    },
    async redirectRules(shouldRedirect) {
      if(this.getURLInCookie()){
        await this.pageRedirect(shouldRedirect);
      }
    },
    async pageRedirect(shouldRedirect) {
      const { pathname } = window.location;
      const hostPathUrl = `https://www.wasteconnections.com${pathname}/`;
      const urlOfUserLocation = new URL(this.getURLInCookie());
      const isUrlMatchAPIUrl = await this.fetchMuleAPIDevFromAddress(hostPathUrl);

      if (shouldRedirect && urlOfUserLocation.pathname !== pathname && isUrlMatchAPIUrl) {
        this.gTagSendEvents('Search - Page Redirect', this.$cookies.get(this.COOKIENAME)?.zip, "select_content", "engagement");
        window.location.href = urlOfUserLocation.href;
      }
    },
    showRedirectCard() {
      const { href, pathname, hostname } = window.location;

      // Check if the hostname matches the specific hostname
      if (hostname !== 'www.wasteconnections.com') {
        return false;
      }

      const pathSegment = pathname.split('/').filter(Boolean)[0];
      const cookieURL = this.getURLInCookie();
      const urlOfUserLocation = cookieURL ? new URL(cookieURL).href : null;

      return cookieURL && href !== urlOfUserLocation && !(pathSegment in districts_list);
    },
    setReadOnly(value) {
      this.readOnly = value;
    },
    closeMenu() {
      this.menu = false;
    },
    clearInput() {
      this.searchValue = '';
      this.displayErrorMessage = '';
      this.setReadOnly(false);
    },
    gTagSendEvents(label, value, action, category, leadType, leadCategory, district) {
        if (window.google_tag_manager && typeof window.dataLayer !== "undefined" && typeof action !== "undefined" && typeof category !== "undefined" && typeof label !== "undefined" && typeof value !== "undefined") {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "eventTracking",
              action: action,
              event_category: category,
              event_label: label,
              value: value,
              lead_type: leadType,
              lead_category: leadCategory,
              lead_district: district,
            });
        }
    }
  }
}
</script>

<style scoped>
.border-3 {
  border-radius: 10px 10px 10px 10px;
  border: 2px solid var(--grey-outline, #D3D3D3);
}
.border-20 {
  border-radius: 10px 10px 10px 10px !important;
  border: 2px solid var(--grey-outline, #D3D3D3) !important;
}
.custom-shadow, ::v-deep .v-input__slot {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10) !important;
}
.v-application .rounded-lg {
  border-radius: 10px!important;
}
.break-word {
  word-break: break-word;
}
::v-deep .theme--light.v-input--is-disabled, ::v-deep .theme--light.v-input--is-disabled input, ::v-deep .theme--light.v-input--is-disabled textarea, ::v-deep .theme--light.v-icon.v-icon.v-icon--disabled {
  color: initial !important;
}
::v-deep .v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content {
  opacity: 1 !important;
  color: #4e4e4e;
}
.v-menu__content {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important;
  border-radius: 10px 10px 10px 10px !important;
}
::v-deep .v-input__prepend-outer {
  margin: 18px 9px !important;
}
::v-deep .v-input__append-outer {
  margin-top: 0px !important;
}
::v-deep .v-skeleton-loader__text {
  margin-bottom: 0px;
}
</style>
