/**
 * District List for Redirect.
 */

// Export list of districts from webflow collections
export const districts_list = {
	// Coming from WCN Worker
	'lakeside-disposal-and-recycle': '2146',
	'lakeshore-disposal': '2212',
	'green-waste': '2413',
	'north-star-borough': '2451',
	'central-region-office': '3000',
	'casper': '3021',
	'scottsbluff-nebraska': '3045',
	'ogallala': '3046',
	'schaben-sanitation': '3047',
	'fremont': '3054',
	'southeast-nebraska': '3059',
	'glenwood': '3132',
	'heber-springs': '3220',
	'ash-flat': '3221',
	'midway': '3222',
	'phoenix': '3303',
	'tucson': '3305',
	'southern-oklahoma': '5005',
	'chickasha': '5007',
	'b-and-b-sanitation': '5010',
	'duncan-ok': '5016',
	'wichita': '5025',
	'bartlesville': '5029',
	'lawton': '5031',
	'lubbock': '5114',
	'houston': '5120',
	'angleton': '5131',
	'alvin': '5132',
	'el-campo': '5133',
	'austin': '5150',
	'north-austin': '5151',
	'seguin': '5155',
	'johnson-city': '5156',
	'san-antonio': '5157',
	'waco': '5165',
	'stephenville': '5167',
	'brownwood': '5168',
	'kilgore': '5175',
	'palestine': '5176',
	'dallas': '5180',
	'waxahachie': '5182',
	'mckinney': '5183',
	'texoma': '5185',
	'northwest-texas': '5187',
	'fort-worth': '5190',
	'justin': '5191',
	'weatherford': '5193',
	'colorado-springs': '5315',
	'pueblo': '5316',
	'memphis': '6010',
	'waste-connections-of-mississippi': '6022',
	'knoxville': '6032',
	'clarksville': '6034',
	'baxter': '60560',
	'hazard': '60561',
	'martin': '60562',
	'campbellsville': '60563',
	'jackson': '60564',
	'somerset': '60565',
	'columbia': '60566',
	'lily': '60567',
	'clevelandtn': '6061',
	'alabama': '6080',
	'timber-ridge-hauling': '6091',
	'cape-girardeau': '6095',
	'charlotte': '6111',
	'monroe-nc': '6113',
	'greenville-sc': '6120',
	'monroe-la': '6131',
	'shreveport': '6135',
	'natchitoches': '6136',
	'alexandria-louisiana': '6160',
	'leesville-louisiana': '6161',
	'new-orleans': '6170',
	'lafayette-opelousas': '6187',
	'baltimore': '6350',
	'chambersburg': '6370',
	'tampa': '6410',
	'pinellas': '6416',
	'palmetto': '6420',
	'pasco-west': '6425',
	'pasco-east': '6426',
	'fort-myers-naples': '6434',
	'labelle': '6436',
	'sebring-highlands': '6437',
	'miami': '6440',
	'orlando': '6460',
	'lake-county': '6470',
	'sumter': '6473',
	'georgia': '6501',
	'oakridge': '6832',
	// Coming from WCN Sitemap
	"colorado": '',
	"st-louis": '',
	"new-york-city": '',
	"eastern-kentucky": '',
	"oklahoma-city": '',
	"desoto-county": '',
	"sequim": '',
	"rhino-removal": '',
	"arizona": '',
	"des-moines": '',
	"denver": '',
};